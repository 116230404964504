import axios from 'axios'
import { store } from '../store/index'

var  server = window.location.protocol + '//'+ window.location.hostname + ':8000'
if (window.location.href.indexOf(':8080') < 0 && window.location.href.indexOf(':8000') < 0) {
  server = window.location.protocol + '//'+ window.location.hostname + '/api'
} 

export default {

  getServerUrl(){
    return server;
  },

  getData () {
    return axios.get('/static/data.json')
  },
  saveInfos (tool, cause, screennr, screentitle) {
    var sessionId = store.getters.getSession.sessionId ?? 'fehler'
    var infoObject = {
      "session": sessionId,
      "tool": tool,
      "cause": cause,
      "screennr" : screennr,
      "screentitle" : screentitle
  }
    return axios.post(server+'/info.php', JSON.stringify(infoObject),{  headers: {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': window.location.protocol + '//'+ window.location.hostname,
      'Access-Control-Allow-Credentials': 'true',
      'Content-Type': 'application/json;charset=utf-8'
    }})
  },

  getPlzInfos (plz) {
    if (window.location.href.indexOf(':8080') < 0 && window.location.href.indexOf(':8000') < 0) {
      return axios.get(server+'/plzsuche.php/?plz='+plz,{  headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': window.location.protocol + '//'+ window.location.hostname,
        'Access-Control-Allow-Credentials': 'true',
        'Content-Type': 'application/json;charset=utf-8'
      }})
    }
    else{
      return axios.get('/static/plzs.json')
    }
    
  },

  sendMail (email, subject, body) {
    var sendObject = {
      "email": email,
      "subject": subject,
      "body": body
    }
    return axios.post(server+'/email.php', JSON.stringify(sendObject),{  headers: {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': window.location.protocol + '//'+ window.location.hostname, 
      'Access-Control-Allow-Credentials': 'true',
      'Content-Type': 'application/json;charset=utf-8'
    }})
  }

}
