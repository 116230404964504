<template>
  <div>
    <div class="container viewContainer">
      <h1 style="text-align: center">Unsere Miet-Expertinnen und -Experten helfen gerne weiter</h1>
      <p style="text-align: center">Wir empfehlen Ihnen, sich fachkundig beraten zu lassen. Der Deutsche Mieterbund und die lokalen Mietervereine in Ihrer Nähe stehen Ihnen mit ihrer Expertise zum Thema Mietrecht zur Verfügung. Wählen sie zwischen Direkthilfe per Telefon und E-Mail oder nehmen Sie Kontakt mit Ihrem lokalen Mieterverein auf. Zusätzlich können Sie sich die Berechnung der Mietminderung per Mail zusenden lassen.</p>
      <div class="spacer m" />
      <router-link to="/direkthilfe"><button class="btn btn-primary"><span>Direkthilfe</span></button></router-link>
      <div class="spacer s" />
      <router-link to="/mieterverein"><button class="btn btn-primary"><span>Lokalen Meiterverein suchen</span></button></router-link>
      <div class="spacer s" />
      <router-link v-if="getRelevantDays() > 0" to="/permail"><button class="btn btn-secondary"><span>Ihre Berechnung per Mail</span></button></router-link>
    </div>
    <!--
    <div id="direkthilfeModal" class="modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">E-Mail-Direkthilfe</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div class="modal-body">
            <p>
              Brauchen Sie schnelle Hilfe und Informationen zu Fragen und Problemen im Mietrecht?
            </p>
            <p>
              Dann sind Sie bei der E-Mail-Direkthilfe von Mieterbund24 genau richtig. Die Mietrechtsexperten vom Deutschen Mieterbund (DMB) sind täglich rund um die Uhr für eine Online-Rechtsberatung erreichbar und bearbeiten Ihre Anfrage in der Regel innerhalb von 6 Stunden. 
            </p>
            <p>
              Für die Beantwortung der Frage ist die Zahlung eines Beitrags von einmalig 25,- Euro erforderlich.
            </p>
          </div>
        </div>
      </div>
    </div>
    -->
  </div>  
</template>
<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'Kontakt',
    components: {
    },
    data() {
      return { 
      }
    },
    computed: {
      ...mapGetters([
          'getSelectedTime'
      ])
    },
    methods: {
      getRelevantDays() {
        var from = new Date(this.getSelectedTime.from)
        var to = new Date(this.getSelectedTime.to)
        var informed = new Date(this.getSelectedTime.informed)
        return to - informed < 0 ? 0 : to - informed
      }
    }
  }
</script>