<template>
  <div>
    <img v-if="getCurrentProgress==getMaxProgress" :src="require('@/assets/img/smart-mieter_city-2.svg')" class="skyline">
    <div class="container">
      <div v-if="getCurrentProgress>0 && getCurrentProgress != 99" class="progress">
        <div class="progress-bar" role="progressbar" :style="'width: '+progressBarWidth+'%'" :aria-valuenow="getCurrentProgress" aria-valuemin="0" aria-valuemax="100" />
      </div>
      <button v-if="getCurrentProgress>0" class="backButton" @click="goBack">
        zurück
      </button>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Header',
    components: {
    },
    data() {
      return {
        progress: 0
      }
    },
    computed: {
      progressBarWidth () {
        return (this.getCurrentProgress * 100)/this.getMaxProgress
      },
      ...mapGetters([
        'getMaxProgress', 'getCurrentProgress'
      ]),
    },
    created() {
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      }
    },
   
  }
</script>