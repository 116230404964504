<template>
  <div class="informed container viewContainer">
    <h1>Wurde die Vermieterin bzw. der Vermieter bereits informiert?</h1>
    <div class="spacer m" />
    <div :class="arrows()">
      <div class="col">
        <button type="button" :class="isSelected('yes')" @click="setYesResponse()">Ja</button>
      </div>
      <div class="col">
        <button type="button" :class="isSelected('no')" @click="setNoResponse()">Nein</button>
      </div>
    </div>
    <div v-if="response==true || time != null" class="row">
      <div class="col">
        <h5>Wann?</h5>
        <input v-model="time" :class="isSelected('date')" :min="getSelectedTime.from" :max="getSelectedTime.to" type="date" class="form-control">
      </div>
    </div>
    <div v-if="response==false && getSelectedTime.to >= getToday() " class="row">
      <div class="col">
        <button type="button" :class="isSelected('informLandlord')" @click="informLandlordFn()">Mangel besteht noch</button>
      </div>
      <div class="col">
        <button type="button" :class="isSelected('end')" @click="toLateFn()">Mangel wurde beseitigt</button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p v-if="informLandlord" class="infoText marginBottom">
          <strong>Melden Sie den Mangel umgehend Ihrer Vermieterin bzw. Ihrem Vermieter</strong><br>
          Sie können nicht mindern, wenn Sie den Mangel vor der Beseitigung nicht bei Ihrer Vermieterin bzw. Ihrem Vermieter angezeigt haben. Eine Ausnahme gilt zum Beispiel, wenn die Vermieterin bzw. der Vermieter den Mangel bereits kennt oder jegliche Mängelbeseitigung bereits von vornherein abgelehnt hat.<br><br>
          Da unsicher sein kann, ob in Ihrem Fall eine Ausnahme vorliegt, empfehlen wir Ihnen den Mangel jetzt der Vermieterin bzw. dem Vermieter anzuzeigen.
        </p>
        <p v-if="end" class="infoText marginBottom">
          <strong>Sie haben wahrscheinlich keinen Anspruch auf Mietminderung</strong><br>
          Sie können nicht mindern, wenn Sie der Vermieterin bzw. dem Vermieter den Mangel vor der Beseitigung nicht angezeigt haben. Eine Ausnahme gilt, wenn die Vermieterin bzw. der Vermieter den Mangel bereits kennt oder jegliche Mängelbeseitigung bereits von vornherein abgelehnt hat.<br><br>
          Möchten Sie prüfen, ob in Ihrem Fall ausnahmsweise ein Anspruch auf Mietminderung besteht, können Sie die Expertinnen und Experten des Mieterbundes kontaktieren.
        </p>
      </div>
    </div>
    <div class="goToNextContainer">
      <!-- <button v-if="informLandlord" type="button" class="btn btn-secondary">Formular herunterladen</button> -->
      <!-- <button v-if="informLandlord" data-bs-target="#sendModal" data-bs-toggle="modal" type="button" class="btn btn-secondary">Formular versenden</button> -->
      <button v-if="end" type="button" class="btn btn-primary" @click="goToContact()">Kontakt</button>
      <button v-if="!end" type="button" class="btn btn-primary" :disabled="!checkDate" @click="save()"><span>Weiter</span></button>
    </div>
    <!--
    <div id="sendModal" class="modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Mustervorlage verschicken</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div class="modal-body">
            <input v-model="email" type="email" class="form-control">
            <button :disabled="emailvalid" @click="sendMail" type="button" class="btn btn-secondary">Formular versenden</button>
          </div>
        </div>
      </div>
    </div>
    -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { Modal } from 'bootstrap';
import DataService from '@/services/DataService'
export default {
  name: 'Informed',
  components: {
  },
  data() {
    return {
      time: '',
      response: null,
      showTimeInput: false,
      informLandlord: false,
      end: false,
      emailvalid: false,
      email: '',
      sendModal: null,
      successModal: null,
      failModal: null
    }
  },
  computed: {
    ...mapGetters([
        'getSelectedCase', 'getSelectedGrade', 'getSelectedTime'
    ]),
    checkDate () {
      var fromDate = new Date(this.getSelectedTime.from)
      var infoDate = new Date(this.time)
      return infoDate != null && fromDate != null && (infoDate - fromDate) >= 0 || this.informLandlord == true
    }
  },
  created () {
    this.time = this.getSelectedTime.informed
    this.$store.commit('setCurrentProgress', 3)
    DataService.saveInfos('Mietminderung', this.getSelectedCase.name, 6, 'Vermieter')
  },
  methods: {
    arrows() {
      var classString = "row basicQuestion"
      console.log(this.getSelectedTime.to)
      console.log(this.getToday())
      if ( this.getSelectedTime.to < this.getToday() ) {
        classString += " noArrows"
      }
      return classString
    },
    setYesResponse() {
      this.response=true
      this.informLandlord = false
      this.end = false
    },
    getToday() {
      var today = new Date()
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      today = yyyy + '-' + mm + '-' + dd;
      return today
    },
    setNoResponse () {
      this.time = null
      this.showTimeInput = false
      var timeObject = this.getSelectedTime
      timeObject.informed = this.time
      this.$store.commit('setSelectedTime', timeObject)
      this.response = false
      this.informLandlord = false
      this.end = false
      if ( this.getSelectedTime.to < this.getToday() ) {
        this.end = true
      }
    },
    save () {
      var timeObject = this.getSelectedTime
      timeObject.informed = this.time
      this.$store.commit('setSelectedTime', timeObject)
      this.$router.push('/miete')
    },
    informLandlordFn () {
      this.informLandlord=true
      this.end = false
    },
    toLateFn() {
      this.informLandlord=false
      this.end = true
    },
    goToContact() {
      var timeObject = this.getSelectedTime
      timeObject.informed = new Date()
      this.$store.commit('setSelectedTime', timeObject)
      this.$router.push('/kontakt')
    },
    isSelected(answer) {
      var classString = "btn btn-selectVariant"
      if ( answer === "yes" && this.response === true ) {
        classString = "btn btn-selectVariant active"
      }
      if ( answer === "no" && this.response === false ) {
        classString = "btn btn-selectVariant active"
      }
      if ( answer === "end" && this.end === true ) {
        classString = "btn btn-selectVariant active"
      }
      if ( answer === "informLandlord" && this.informLandlord === true ) {
        classString = "btn btn-selectVariant active"
      }
      if ( answer === "date") {
        classString = ""
        if ( this.time ) {
          classString = "active"
        }
      }
      return classString
    }
  },

}
</script>