<template>
  <div>
    <div class="container viewContainer" style="text-align: center;">
      <h1>Direkthilfe per Telefon oder E-Mail</h1>
      <p>
        Brauchen Sie die Antwort auf Ihre Mietrechtsfrage sofort? 
      </p>
      <p>
        Rufen Sie jetzt an unter: 
      </p>
      <p>
        Täglich von 10 bis 20 Uhr. 7 Tage in der Woche. Das Gespräch kostet zwei Euro pro Minute. Ab der zweiten Minute wird sekundengenau abgerechnet (60/1). Bei einem Anruf über Mobilfunknetze können höhere Kosten entstehen. Sie zahlen über Ihre Telefonrechnung. Es entstehen keinerlei weitere Verpflichtungen.
      </p>
      <p>
        <a class="phoneLink" href="tel:09001200012"><button class="btn btn-primary">Erstberatung per Telefon<h4 style="font-size: 11px; margin-bottom: 0px;">(0900 / 12 000 12)</h4></button></a>
      </p>
      <div class="spacer l" />
      <p>
        Brauchen Sie schnelle Hilfe und Informationen zu Fragen und Problemen im Mietrecht?
      </p>
      <p>
        Dann sind Sie bei der E-Mail-Direkthilfe von Mieterbund24 genau richtig. Die Experten und Expertinnen vom Deutschen Mieterbund (DMB) sind täglich rund um die Uhr für eine Online-Rechtsberatung erreichbar und bearbeiten Ihre Anfrage in der Regel innerhalb von 6 Stunden. 
      </p>
      <p>
        Für die Beantwortung der Frage ist die Zahlung eines Beitrags von einmalig 25,- Euro erforderlich.
      </p>
      <p>
        <a target="blank" href="https://www.mieterbund24.de/"><button class="btn btn-primary">weiter zu Mieterbund24.de</button></a>
      </p>
    </div>
  </div>  
</template>
<script>
  export default {
    name: 'Hilfe',
    components: {
    },
    data() {
      return {
      }
    },
  }
</script>