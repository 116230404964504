import { createApp } from 'vue'
import { store } from '@/store/index.js'

import App from './App.vue'

import router from '@/router/index.js'


import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "@/assets/css/main.css"

const app = createApp(App)
app.use(store)
app.use(router)

app.mount('#app')

