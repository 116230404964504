import {createRouter, createWebHistory}  from 'vue-router'
import WhatsWrong from '@/views/WhatsWrong.vue'
import HowBad from '@/views/HowBad.vue'
import TimePeriod from '@/views/TimePeriod.vue'
import RentCalculator from '@/views/RentCalculator.vue'
import SpaceCalculator from '@/views/SpaceCalculator.vue'
import Result from '@/views/Result.vue'
import Home from '@/views/Home.vue'
import Informed from '@/views/Informed.vue'
import Questions from '@/views/Questions.vue'
import Contact from '@/views/Contact.vue'
import Hilfe from '@/views/Hilfe.vue'
import Mieterverein from '@/views/Mieterverein.vue'
import KeineHilfe from '@/views/KeineHilfe.vue'


const routes = [
  {
    path: '/',
    component: Home,
    name: 'startScreen'
  },
  {
    path: '/mangel',
    component: WhatsWrong
  },
  {
    path: '/grad',
    component: HowBad
  },
  {
    path: '/zeitraum',
    component: TimePeriod
  },
  {
    path: '/informiert',
    component: Informed
  },
  {
    path: '/beschreibung',
    component: Questions
  },
  {
    path: '/miete',
    component: RentCalculator
  },
  {
    path: '/groesse',
    component: SpaceCalculator
  },
  {
    path: '/ergebnis',
    component: Result
  },
  {
    path: '/kontakt',
    component: Contact
  },
  {
    path: '/direkthilfe',
    component: Hilfe
  },
  {
    path: '/mieterverein',
    component: Mieterverein
  },
  {
    path: '/permail',
    component: KeineHilfe
  }
]

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
})

export default router
