<template>
  <div class="container viewContainer">
    <h1>Wie lange besteht / bestand der Mangel?</h1>
    <div class="spacer m" />
    <div class="periodContainer">
      <div class="moment">
        <h5>Seit:</h5>
        <div class="spacer xs" />
        <input v-model="from" :min="tenYearsAgo()" :max="getToday()" type="date" class="form-control">
      </div>
      <div class="moment">  
        <h5>Bis:</h5>
        <div class="spacer xs" />
        <div class="form-check">
          <input v-model="todayChecked" class="form-check-input" type="checkbox" @change="setToday">
          <label class="form-check-label" for="flexCheckChecked">
            bis heute
          </label>
        </div>
        <div class="spacer xs" />
        <input v-model="to" :min="from" :max="getToday()" type="date" class="form-control" :disabled="todayChecked">
      </div>
    </div> 
    <div class="goToNextContainer">
      <p v-if="errorHint !== ''" class="warning">
        {{ errorHint }}
      </p>
      <button type="button" class="btn btn-primary" @click="save"><span>Weiter</span></button>
    </div> 
  </div>
</template>
<script>
  import DataService from '@/services/DataService'
  import { mapGetters } from 'vuex'
  export default {
    name: 'TimePeriod',
    components: {
    },
    data() {
      return {
          test: '',
          from:'',
          to:'',
          todayChecked: false,
          errorHint: ""
      }
    },
    computed: {
      ...mapGetters([
        'getSelectedCase', 'getSelectedGrade', 'getSelectedTime'
      ])
    },
    created () {
      this.$store.commit('setCurrentProgress', 3)
      if (this.getSelectedTime.from != null) {
        this.from = this.getSelectedTime.from
      }
      if (this.getSelectedTime.to != null) {
        this.to = this.getSelectedTime.to
      }
      DataService.saveInfos('Mietminderung', this.getSelectedCase.name, 3, 'Zeitabfrage')
    },
    methods: {
      tenYearsAgo() {
        var tenYearsAgo = new Date()
        var dd = String(tenYearsAgo.getDate()).padStart(2, '0');
        var mm = String(tenYearsAgo.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = tenYearsAgo.getFullYear() - 10;
        tenYearsAgo = yyyy + '-' + mm + '-' + dd;
        console.log(tenYearsAgo)
        return tenYearsAgo
      },
      getToday() {
        var today = new Date()
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;
        return today
      },
      setToday() {
        var today = this.getToday();
        this.to = today
      },
      save() {
       
        var isValid = true
        var fromDate = new Date(this.from)
        var toDate = new Date(this.to)
        if (this.to === null || this.to === "") {
          isValid = false
          this.errorHint = "Bitte wählen Sie ein Datum bei 'BIS' an"
        }
        if (this.from === null || this.from === "") {
          isValid = false
          this.errorHint = "Bitte wählen Sie ein Datum bei 'SEIT' an"
        }
        if (this.to != "" && this.from  != "" && fromDate > toDate) {
          isValid = false
          this.errorHint = "Das Datum bei 'SEIT' liegt nach dem Datum bei 'BIS'"
        }
        if (this.to != "" && this.from  != "" && fromDate < new Date(this.tenYearsAgo())) {
          isValid = false
          this.errorHint = "Ups! Der Zeitpunkt liegt mehr als 10 Jahre zurück. Bitte wählen Sie ein neues Datum."
        }
        if (isValid === true) {
          var timeObject = this.getSelectedTime
          timeObject.from = this.from
          timeObject.to = this.to
          this.$store.commit('setSelectedTime', timeObject)
          this.$router.push('/informiert')
        }
      }
    }
  }
</script>