<template>
  <div>
    <div class="container viewContainer">
      <h1>Um welchen Mangel geht es?</h1>
      {{ $store.state.selectedCaseId }}
      <div class="spacer l" />
      <div class="caseContainer">
        <div v-for="(item, indexCase) in cases" :key="indexCase" class="card" @click="selectCase(item)">
          <img class="card-img-top" :src="imagePath(item.image)" :alt="item.name">
          <div class="card-body">
            <h5 class="card-title">{{ item.name }}</h5>
          </div>
        </div>
      </div> 
    </div>
    <div id="hinweisModal" class="modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Hinweis</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div class="modal-body">
            <p>
              Nach dem Gesetz muss der Vermieter Mängel oder Fehler der Mietsache beseitigen bzw. reparieren. Tut er das nicht, kann der Mieter unter Umständen den Mangel selbst auf Kosten des Vermieters beheben lassen, er hat möglicherweise Schadensersatzansprüche oder ein Zurückbehaltungsrecht an der Miete. Bei "schwersten" Mängeln kann er sogar fristlos kündigen.
            </p>
            <p>
              Das wichtigste Mieterrecht bei Wohnungsmängel ist aber die Mietminderung. Solange Wohnungsmängel vorliegen, kann der Mieter die Miete kürzen, das heißt, er hat das Recht weniger Miete zu zahlen.
            </p>
          </div>  
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataService from '@/services/DataService'
import { mapGetters } from 'vuex'
export default {
  name: 'WhatsWrong',
  components: {
  },
  data() {
    return {
        test: '',
        cases: []
    }
  },
  computed: {
      ...mapGetters([
      'getSelectedCase', 'getSession'
    ])
  },
  created() {
    console.log(this.cases.length)
    this.$store.commit('setCurrentProgress', 1)
    DataService.getData().then((response) => {
      this.cases = response.data.cases
    }).catch((err) => 
    {
      console.log(err)
    })
    DataService.saveInfos('Mietminderung', '', 0, 'Auswahl')
  },
  
  methods: {
    testMethod() {
    },
    selectCase (item) {
      this.$store.commit('setSelectedCase', item)
      this.$store.commit('setSelectedGrade', {})
      this.nextClicked()
    },
    imagePath ( path ) {
      return require('@/assets/img/'+path)
    },
    nextClicked () {
      if (this.getSelectedCase.type === 'grade') {
        this.$router.push('/grad')
      } else if (this.getSelectedCase.type === 'questions') {
        this.$router.push('/beschreibung')
      }
    }
  }
}
</script>