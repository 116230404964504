import { createStore } from 'vuex'
const { v4: uuidv4 } = require('uuid')

export const store = createStore({
  state: {
    selectedCase: localStorage.getItem('selectedCase') || '{}',
    selectedGrade: localStorage.getItem('selectedGrade') || '{}',
    selectedTime: localStorage.getItem('selectedTime') || '{}',
    selectedRent: localStorage.getItem('selectedRent') || '0',
    currentProgress: localStorage.getItem('currentProgress') || '0',
    maxProgress: localStorage.getItem('maxProgress') || '5',
    session: localStorage.getItem('session') || '{}'
  },
  getters: {
    getSelectedCase: state => {
      return  JSON.parse( state.selectedCase)
    },
    getSelectedGrade: state => {
      return  JSON.parse( state.selectedGrade)
    },
    getSelectedTime: state => {
      return  JSON.parse( state.selectedTime)
    },
    getCurrentProgress: state => {
      return  Number(state.currentProgress)
    },
    getMaxProgress: state => {
      return  Number(state.maxProgress)
    },
    getSelectedRent: state => {
      return  Number(state.selectedRent)
    },
    getSession: state => {
      return  JSON.parse(state.session)
    }
  },
  mutations: {
    setSelectedCase: (state, item) => {
      localStorage.setItem('selectedCase', JSON.stringify(item))
      state.selectedCase = JSON.stringify(item)
    },
    setSelectedGrade: (state, item) => {
      localStorage.setItem('selectedGrade', JSON.stringify(item))
      state.selectedGrade = JSON.stringify(item)
    },
    setSelectedTime: (state, item) => {
      localStorage.setItem('selectedTime', JSON.stringify(item))
      state.selectedTime = JSON.stringify(item)
    },
    setCurrentProgress: (state, item) => {
      localStorage.setItem('currentProgress', String(item))
      state.currentProgress =  String(item)
    },
    setMaxProgress: (state, item) => {
      localStorage.setItem('maxProgress', String(item))
      state.maxProgress =  String(item)
    },
    setSelectedRent: (state, item) => {
      localStorage.setItem('selectedRent', String(item))
      state.selectedRent =  String(item)
    },
    createSession: (state) => {
      var id = uuidv4()
      var date = (new Date()).toJSON()
      var item = {
        'sessionId': id,
        'date': date
      }
      localStorage.setItem('session', JSON.stringify(item))
      state.session = JSON.stringify(item)
      console.log('SESSION CREATED')
    },
  },
  actions: {}
})
