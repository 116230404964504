<template>
  <div class="container viewContainer">
    <h1>Wie hoch ist Ihre monatliche Warmmiete?</h1>
    <div class="spacer m" />
    <div class="moreInfoButtons">
      <span style="text-align: center;">Monatliche Grundmiete</span>
      <span style="text-align: center;">einschließlich der monatlichen Nebenkostenzahlungen</span>
    </div>
    <div class="spacer m" />
    <div class="rentInputContainer">
      <input ref="rentInput" v-model="rent" :min="1" type="number" class="form-control rentInput">
    </div>
    <div class="spacer m" />
    <div class="goToNextContainer">
      <button type="button" class="btn btn-primary" :disabled="rent<=0" @click="save()"><span>Weiter</span></button>
    </div>  
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import DataService from '@/services/DataService'
export default {
  name: 'RentCalculator',
  components: {
  },
  data() {
    return {
      rent: ''
    }
  },
  computed: {
    ...mapGetters([
        'getSelectedCase', 'getSelectedGrade', 'getSelectedTime', 'getSelectedRent'
    ])
  },
  created () {
    this.$store.commit('setCurrentProgress', 4)
    this.rent = this.getSelectedRent
    console.log(this.rent)
    if (this.rent === 0) {
      this.rent = ""
    }
    setTimeout(x => {
      this.$nextTick(() => this.setFocus()); // this works great, just watch out with going to fast !!!
    }, 100);
    DataService.saveInfos('Mietminderung', this.getSelectedCase.name, 5, 'Mieteingabe')
  },
  methods: {
    save() {
      this.$store.commit('setSelectedRent', this.rent)
      this.$router.push("/ergebnis")
    },
    setFocus: function() {
      // Note, you need to add a ref="search" attribute to your input.
      this.$refs.rentInput.focus();
    }
  }
}
</script>