<template>
  <div class="questions container viewContainer">
    <h1>{{ getSelectedCase.question.text }}</h1>
    <div class="spacer m" />
    <div class="row basicQuestion">
      <div class="col">
        <div v-for="(answer, indexa) in getSelectedCase.question.answers" :key="indexa">
          <button type="button" :class="isSelected(answer)" @click="setResponse(answer)">{{ answer.text }}</button>
          <p v-if="isSelectedAnswer(answer) && answer.description != null" class="gradText">
            {{ answer.description }}
          </p>
        </div>
      </div>  
    </div>
    <div v-if="response !== null && response.question != null" class="basicQuestionContainer row">
      <div class="col">
        <h1>{{ response.question.text }}</h1>
        <div class="spacer m" />
        <div v-for="(answer, indexb) in response.question.answers" :key="indexb" class="basicQuestion">
          <button type="button" :class="isSelected(answer)" @click="setInnerResponse(answer)">{{ answer.text }}</button>
          <p v-if="isSelectedAnswer(answer) && answer.description != null" class="gradText">
            {{ answer.description }}
          </p>
        </div>
      </div>
    </div>
    <button style="margin-bottom: 20px;" type="button" class="btn btn-primary" :disabled="!innerResponse" @click="save()"><span>Weiter</span></button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import DataService from '@/services/DataService'
export default {
  name: 'Questions',
  components: {
    },
  data() {
    return {
      time: '',
      response: null,
      innerResponse: null,
      end: false
    }
  },
  computed: {
    ...mapGetters([
        'getSelectedCase', 'getSelectedGrade', 'getSelectedTime'
    ])
  },
  created () {
    DataService.saveInfos('Mietminderung', this.getSelectedCase.name, 1, 'Zusatzfragen')
    this.$store.commit('setCurrentProgress', 1)
  },
  methods: {
    setResponse(answer) {
      this.response = answer
      if (answer.question == null) {
        this.innerResponse = answer
      }
    },
    setInnerResponse(answer) {
      this.innerResponse = answer
      console.log(this.innerResponse)
    },
    save () {
      var selectCase = this.getSelectedCase
      if (this.innerResponse  != null) {
        selectCase.important = this.innerResponse.important
        selectCase.grades = this.innerResponse.grades
        selectCase.name = this.innerResponse.text
      } else if (this.response != null ) {
        selectCase.name = this.response.text
      }
      console.log(selectCase)
      this.$store.commit('setSelectedCase', selectCase)
      if (selectCase.grades != null && selectCase.grades.length === 1) {
        this.$store.commit('setSelectedGrade', selectCase.grades[0])
        this.$router.push('/zeitraum')
      } else if (selectCase.grades != null && selectCase.grades.length > 1) {
        this.$router.push('/grad')
      } else if (this.response != null && this.response.text == 'Flächenabweichung') {
        this.$router.push('/groesse')
      } 
      
    },
    isSelected(answer) {
      var classString = "btn btn-selectVariant"
      if (answer != null && this.response != null && (answer.text == this.response.text || (this.innerResponse && answer.text == this.innerResponse.text) )) {
        classString = "btn btn-selectVariant active"
      }
      return classString
    },
    isSelectedAnswer (answer) {
      return answer != null && this.response != null && (answer.text == this.response.text || (this.innerResponse && answer.text == this.innerResponse.text))
    }
  }
}
</script>