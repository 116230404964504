<template>
  <div class="container viewContainer">
    <h1>{{ getSelectedCase.gradesText }}</h1>
    <div class="spacer l" />
    <div class="sliderContainer">
      <Slider v-model="selectedGrad" :min="min" :max="max" :tooltips="false" :lazy="false" :change="selectGrade()" />
    </div>
    <div :class="'sliderLabels gradesNumber_' + getSelectedCase.grades.length">
      <span v-for="(grad, indexGrad) in getSelectedCase.grades" :key="indexGrad" :class="isActiveGrad(indexGrad)" @click="selectedGrad = indexGrad">
        {{ grad.sliderText }}
      </span>
    </div>
    <p class="gradText">
      <strong>{{ headline }}</strong>
      {{ text }}
    </p>
    <div class="goToNextContainer">
      <button type="button" class="btn btn-primary" @click="$router.push('/zeitraum')"><span>Weiter</span></button>
    </div> 
  </div>
</template>
<script>
  //https://github.com/vueform/slider
  import Slider from '@vueform/slider'
  import DataService from '@/services/DataService'
  import { mapGetters } from 'vuex'
  export default {
    name: 'HowBad',
    components: {
      Slider
    },
    data() {
      return {
          selectedGrad: 0,
          min: 0,
          max: 1
      }
    },
    computed: {
      ...mapGetters([
        'getSelectedCase', 'getSelectedGrade'
      ]),
      headline () {
        console.log(this.selectedGrad)
        return this.getSelectedCase != null && this.getSelectedCase.grades != null && this.getSelectedCase.grades[this.selectedGrad] != null ? this.getSelectedCase.grades[this.selectedGrad].headline : 'Fehler'
      },
      text () {
        return this.getSelectedCase != null && this.getSelectedCase.grades != null && this.getSelectedCase.grades[this.selectedGrad] != null ? this.getSelectedCase.grades[this.selectedGrad].text : 'Fehler'
      }
    },
    created() {
      if (this.getSelectedCase.grades != null) {
        this.max = this.getSelectedCase.grades.length - 1
      }
      console.log('SelectedGrade:')
      console.log(this.getSelectedGrade)
      if (this.getSelectedGrade.headline != null && this.getSelectedCase.grades != null) {
        const index = this.getSelectedCase.grades.findIndex(item => item.headline === this.getSelectedGrade.headline);
        this.selectedGrad = index
      }
      DataService.saveInfos('Mietminderung', this.getSelectedCase.name, 2, 'Gradabfrage')
      this.$store.commit('setCurrentProgress', 2)
      
    },
    methods: {
      testMethod() {
      },
      selectGrade() {
        if (this.getSelectedCase.grades != null && this.getSelectedCase.grades.length > this.selectedGrad) {
          this.$store.commit('setSelectedGrade', this.getSelectedCase.grades[this.selectedGrad])
        }
      },
      isActiveGrad(indexGrad) {
        if(indexGrad == this.selectedGrad) {
          return "active"
        }
      }
    }
  }
</script>
<style src="@vueform/slider/themes/default.css"></style>