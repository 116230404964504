<template>
  <div class="container viewContainer">
    <h1> Wie groß ist Ihre Wohnung laut Mietvertrag?</h1>
    <div class="spacer s" />
    <div class="spaceInputContainer">
      <input ref="spaceInput" v-model="spaceContract" :min="1" type="number" class="form-control rentInput">
    </div>
    <div class="spacer m" />
    <h1> Wie groß ist Ihre Wohnung tatsächlich?</h1>
    <div class="spacer s" />
    <div class="spaceInputContainer">
      <input ref="space2Input" v-model="spaceReal" :min="1" type="number" class="form-control rentInput">
    </div>
    <div class="spacer m" />
    <div class="goToNextContainer">
      <button type="button" class="btn btn-primary" :disabled="diffPercent>0.1 || spaceContract === '' || spaceReal === ''" @click="save()"><span>Weiter</span></button>
    </div>  
    <p v-if="showHint" style="margin-top: 20px;" class="warning">
      <span v-if="diffPercent > 0">
        <strong>Errechnete Abweichung: {{ diffPercent*100 }}%</strong><br>
        Sie haben wahrscheinlich keinen Anspruch auf Mietminderung. Denn nach der Rechtsprechung des Bundesgerichtshofs ist eine Mietminderung bei einer Flächenabweichung von unter 10% in der Regel ausgeschlossen.
      </span>  
      <span v-if="diffPercent <= 0">
        Um die Miete zu mindern, muss die tatsächliche Größe der Wohnung kleiner als die im Mietvertrag angegebene Wohnfläche sein.
      </span>
    </p>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import DataService from '@/services/DataService'
export default {
  name: 'SpaceCalculator',
  components: {
  },
  data() {
    return {
      spaceContract: 0,
      spaceReal: 0,
      showFirstInput: true,
      diffPercent: 0,
      showHint: false
    }
  },
  computed: {
    ...mapGetters([
        'getSelectedCase', 'getSelectedGrade', 'getSelectedTime', 'getSelectedRent'
    ])
  },
  created () {
    this.$store.commit('setCurrentProgress', 2)
    DataService.saveInfos('Mietminderung', this.getSelectedCase.name, 2, 'Flächenabweichung')
    setTimeout(x => {
      this.$nextTick(() => this.setFocus()); // this works great, just watch out with going to fast !!!
    }, 100);
    if(this.spaceContract === 0) {
      this.spaceContract = ""
    }
    if(this.spaceReal === 0) {
      this.spaceReal = ""
    }
  },
  methods: {
    save() {

      var diff = this.spaceContract - this.spaceReal
      this.diffPercent = (diff / this.spaceContract).toFixed(2)
      if (this.diffPercent < 0.1) {
        this.showHint = true
      } else { 
        var grade = {
          minReduce:   this.diffPercent * 100,
          maxReduce:   this.diffPercent * 100,
          headline: 'Flächenabweichung'
        }
        console.log(grade)
        this.$store.commit('setSelectedGrade', grade)

        var today = new Date()
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;
        var timeObject = {}
        timeObject.from = today
        timeObject.to = today
  
        this.$store.commit('setSelectedTime', timeObject)
        this.$router.push("/miete")
      }
     
    },
    setFocus: function() {
      // Note, you need to add a ref="search" attribute to your input.
      this.$refs.spaceInput.focus()
    }
  }
}
</script>